class Anchor {
    init() {
        const anchorLinks = document.querySelectorAll('.anchor')
        const header = document.querySelector('header')
        anchorLinks.forEach(link => {
            link.addEventListener('click', (e) => {
                e.preventDefault()

                let blockId = link.getAttribute('href').substr(1),
                    scrollTarget = document.getElementById(blockId),
                    elementPosition = scrollTarget.getBoundingClientRect().top,
                    offsetPosition = elementPosition - header.offsetHeight

                window.scrollBy({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            })
        })
    }
}

export default Anchor