class Showmore {
    init() {
        const showMoreItems = document.querySelectorAll('.showmore')
        showMoreItems.forEach(item => {
            let inner = item.querySelector('.showmore__inner'),
                link = item.querySelector('.showmore__link')

            link.addEventListener('click', (e) => {
                item.style.height = inner.offsetHeight + 'px'
                inner.classList.add('visible')
                link.style.display = 'none'
            })
        })
    }
}

export default Showmore