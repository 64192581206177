class CallbackMap {
    render() {
        const mapMarkers = [
            {
                lat: '55.724829',
                lng: '37.642635',
                title: 'Главный офис',
                phone: '8 800 302 96 86',
                email: 'sales.box@evrazsteel.ru',
                address: '115114, Россия, г. Москва, ул. Летниковская, 10с4'
            },
            {
                lat: '57.626357',
                lng: '39.873664',
                title: 'Офис отдела продаж',
                phone: '8 800 302 96 86',
                email: 'sales.box@evrazsteel.ru',
                address: '150000, Россия, г. Ярославль, ул. Чайковского, д. 40а, оф. 501, 506'
            }
        ]
        let mapCenter = [56.731051, 38.700698]
        if (window.matchMedia("(min-width: 992px)").matches) {
            mapCenter = [56.731051, 36.700698]
        }
        if(typeof ymaps !== 'undefined'){
            ymaps.ready(function (){
                const map = new ymaps.Map("callback_map", {
                    center: mapCenter,
                    zoom: 7
                });
                mapMarkers.forEach(marker => {
                    let myPlacemark = new ymaps.Placemark([marker.lat, marker.lng], {
                            balloonContentHeader: '<span class="marker__title">'+ marker.title +'</span>',
                            balloonContentBody: '<a href="tel:'+ marker.phone +'" class="marker__link">'+ marker.phone +'</a><a href="mailto:'+ marker.email +'" class="marker__link">'+ marker.email +'</a>',
                            balloonContentFooter: '<span class="marker__text">'+ marker.address +'</span>',
                        }
                        , {
                            draggable: false,
                            hideIconOnBalloonOpen: false,
                            preset: 'islands#whiteStretchyIcon',
                            iconLayout: 'default#image',
                            iconImageHref: '/src/img/marker.png',
                            iconImageSize: [135, 140],
                            iconImageOffset: [-62, -88],
                        });
                    map.geoObjects.add(myPlacemark);
                })
            })
        }
    }
}

export default CallbackMap